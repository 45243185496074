import axios from 'axios'
import VUE_APP_API_IAPETUS_URL from './getapi/iapetus'

let apiURL = ''
// console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "development") {
  // alert("开发环境");
  apiURL = 'http://proxy.jiankanghao.net:50206/api/v1'
} else {
  // alert("生产环境");
  apiURL = VUE_APP_API_IAPETUS_URL
}

let http = axios.create({
  timeout: 12000,
  baseURL: apiURL,
  headers: {
    'Content-Type': 'application/json',
    "Authorization": localStorage.getItem('ms_token')
  }
});

export default http;