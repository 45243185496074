<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-form
          :inline="true"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item label="消息内容" prop="name">
            <el-input v-model="ruleForm.keyword"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="region">
            <el-select v-model="ruleForm.send_status" placeholder="请选择状态">
              <el-option
                v-for="(child, index) in send_status"
                :key="index"
                :label="child.name"
                :value="child.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="平台" prop="region">
            <el-select
              v-model="ruleForm.platform_type"
              placeholder="请选择活动区域"
            >
              <el-option
                v-for="(child, index) in platform_types"
                :key="index"
                :label="child.name"
                :value="child.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发送时间">
            <el-col :span="11">
              <el-form-item prop="date1">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="ruleForm.start_time"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1">至</el-col>
            <el-col :span="11">
              <el-form-item prop="date2">
                <el-time-picker
                  placeholder="选择时间"
                  v-model="ruleForm.end_time"
                  style="width: 100%"
                ></el-time-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="medium"
              @click="submitForm('ruleForm')"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <el-button
        type="primary"
        icon="el-icon-lx-add"
        class="handle-add"
        @click="addEdit"
        >新增</el-button
      >
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        v-loading="loading"
      >
        <el-table-column label="序号" align="center" width="50">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 + page_index }}</span>
          </template>
        </el-table-column>
        <el-table-column label="客户端" align="center">
          <template slot-scope="scope">
            <span>{{ getPlatformNype(scope.row.target_device_type) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="推送对象" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.target_user_id == ''">{{
              getusertype(scope.row.target_user_type)
            }}</span>
            <span v-else>{{ scope.row.target_user_id }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="标题"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="content"
          label="消息内容"
          align="center"
        ></el-table-column>

        <el-table-column label="发送时间" align="center">
          <template slot-scope="scope">
            {{ formatDate(scope.row.send_time) }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            {{ formatDate(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="备注"
          align="center"
        ></el-table-column>
        <el-table-column prop="content" label="状态" align="center">
          <template slot-scope="scope">
            {{ sendStatus(scope.row.send_status) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="totalPages > 1">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="currentPage"
          :page-size="page_size"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <el-dialog
      :visible.sync="editVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="135px">
        <div
          style="
            width: 100%;
            height: 40px;
            color: #4296cd;
            padding-left: 80px;
            box-sizing: border-box;
            line-height: 40px;
          "
        >
          消息内容
        </div>
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" @input="onInput()"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="form.content"
            @input="onInput()"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="url">
          <el-input v-model="form.url" @input="onInput()"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="img_path">
          <el-upload
            class="avatar-uploader"
            action
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="cover" :src="cover" class="avatar" alt="加载失败" />
            <em v-else class="el-icon-plus avatar-uploader-icon"></em>
          </el-upload>
        </el-form-item>
        <div style="padding-left: 80px; box-sizing: border-box">
          <hr style="height: 1px; border: none; border-top: 1px dashed #ccc" />
        </div>
        <div
          style="
            width: 100%;
            height: 40px;
            color: #4296cd;
            padding-left: 80px;
            box-sizing: border-box;
            line-height: 40px;
          "
        >
          发送设置
        </div>
        <el-form-item label="user_id" prop="user_id">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            v-model="form.user_id"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户端" prop="window_platform_type">
          <el-select v-model="window_platform_type" class="handle-select">
            <el-option
              v-for="(child, index) in platform_types"
              :key="index"
              :label="child.name"
              :value="child.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送状态" prop="window_platform_type">
          <el-select
            v-model="window_send_type"
            class="handle-select"
            @change="selecsendTypeHandle"
          >
            <el-option
              v-for="(child, index) in send_status"
              :key="index"
              :label="child.name"
              :value="child.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="发送时间"
          prop="send_time"
          v-if="window_send_type == 2"
        >
          <el-date-picker
            v-model="form.send_time"
            type="datetime"
            placeholder="选择日期时间"
            default-time="12:00:00"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="平台类型" prop="window_device_type">
          <el-select
            v-model="window_device_type"
            class="handle-select"
            @change="selecdeviceTypeHandle"
          >
            <el-option
              v-for="(child, index) in device_types"
              :key="index"
              :label="child.name"
              :value="child.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户类型" prop="user_type">
          <el-select
            v-model="user_type"
            class="handle-select"
            @change="selecuserTypeHandle"
          >
            <el-option
              v-for="(child, index) in user_types"
              :key="index"
              :label="child.name"
              :value="child.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
            v-model="form.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer self-dialog-footer">
        <el-button type="primary" @click="saveEdit('ruleForm')"
          >提 交</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { _ } from "vue-underscore";
import http from "../../../../api/tartarusApiConfig";
import http2 from "../../../../api/gaeaApiConfig";
import http3 from "../../../../api/iapetusApiConfig";
import uploadapi from "../../../../api/getapi/upload";
import md5 from "js-md5";
import $ from "jquery";
export default {
  name: "Pushlist",
  data() {
    return {
      cover: "",
      ruleForm: {
        keyword: "",
        send_status: 1,
        start_time: "",
        end_time: "",
        platform_type: 0,
      },
      platform_types: [
        { name: "全部", id: 0 },
        { name: "患者端", id: 1 },
        { name: "医生端", id: 2 },
      ],
      send_status: [
        { name: "立即发送", id: 1 },
        { name: "延时发送", id: 2 },
      ],
      window_select_arr: [],
      window_select_all: [{ name: "全部用户", id: "all" }],
      window_select_doctor: [
        { name: "全部医生", id: "all_doctors" },
        { name: "仅医生", id: "doctor_id" },
      ],
      window_select_patient: [
        { name: "全部患者", id: "all_users" },
        { name: "仅患者", id: "user_id" },
      ],
      window_types_all: [
        { name: "通用H5", type: 1 },
        { name: "医生列表", type: 2 },
        { name: "医生详情", type: 3 },
        { name: "搜索结果页", type: 4 },
        { name: "健康档案列表", type: 5 },
        { name: "关注医生列表", type: 6 },
        { name: "微信小程序", type: 7 },
        { name: "vip开通页", type: 8 },
        { name: "指定医生的图文对话", type: 9 },
        { name: "药品订单详情页", type: 10 },
        { name: "问诊订单详情页", type: 11 },
        { name: "首页", type: 12 },
        { name: "要闻", type: 13 },
        { name: "我的页面", type: 14 },
      ],
      window_placeholder: "请输入手机号",
      window_types: [],
      window_type: 1,
      window_device_type: 0,
      user_type: "",
      device_types: [
        { name: "全部", id: 0 },
        { name: "IOS", id: 1 },
        { name: "Android", id: 2 },
      ],
      user_types: [
        { name: "全部用户", id: "all" },
        { name: "新注册", id: "new_register" },
        { name: "会员", id: "vip" },
        { name: "非会员", id: "not_vip" },
        { name: "执业中医师", id: "执业中医师" },
        { name: "中医爱好者", id: "中医爱好者" },
        { name: "医学生", id: "医学生" },
        { name: "中药师", id: "中药师" },
        { name: "其他中医相关从业人员", id: "其他中医相关从业人员" },
        { name: "护士", id: "护士" },
      ],
      doctors: [],
      window_doctor_keyword: "",
      window_platform_type: 0,
      window_send_type: 1,
      platform_type: 0,
      tableData: [],
      currentPage: 1,
      totalPages: 1,
      page_size: 10,
      pageTotal: 10,
      page_index: 10,
      keyword: "",
      window_keyword: "",
      search_users: [],
      select_users: [],
      defaultProps: {
        label: "phone",
        key: "id",
        pinyin: "nickname",
      },
      loading: false,
      multipleSelection: [],
      delList: [],
      editVisible: false,
      is_add: false,
      devices_show: false,
      form: {},
      submit_obj: {},
      rules: {
        content: [
          { required: true, message: "请填写推送信息", trigger: "blur" },
        ],
        title: [{ required: true, message: "请填写标题", trigger: "blur" }],
        url: [{ required: true, message: "请填写链接", trigger: "blur" }],
        select_doctor_id: [
          { required: true, message: "请选择医生", trigger: "blur" },
        ],
        keywords: [
          { required: true, message: "请填写keywords", trigger: "blur" },
        ],
        appid: [{ required: true, message: "请填写appid", trigger: "blur" }],
        username: [
          { required: true, message: "请填写username", trigger: "blur" },
        ],
        path: [{ required: true, message: "请填写path", trigger: "blur" }],
        miniprogramType: [
          { required: true, message: "请填写miniprogramType", trigger: "blur" },
        ],
        doctor_id: [
          { required: true, message: "请填写doctor_id", trigger: "blur" },
        ],
        doctor_user_id: [
          { required: true, message: "请填写doctor_user_id", trigger: "blur" },
        ],
        doctor_name: [
          { required: true, message: "请填写doctor_name", trigger: "blur" },
        ],
        order_id: [
          { required: true, message: "请填写order_id", trigger: "blur" },
        ],
        send_time: [
          { required: true, message: "请选择发送时间", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请填写备注", trigger: "blur" }],
      },
      transferData: [],
      pickerOptions: {
        disabledDate(time) {
          const currentDate = new Date();
          const thirtyDaysLater = new Date();
          thirtyDaysLater.setDate(currentDate.getDate() + 30);
          return (
            time.getTime() < currentDate.getTime() - 8.64e7 ||
            time.getTime() > thirtyDaysLater.getTime()
          );
        }, // selectableRange: '15:00:00 - 23:59:59',
      },
    };
  },
  created() {
    console.log("qwerrtttt");
    this.getData();
    this.getTransferData();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    beforeAvatarUpload(file) {
      let that = this;
      const isJPG = file.type === "image/jpeg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (isJPG && isLt2M) {
        var ossData = new FormData();
        let imgType = file.type.split("/")[1];
        var time = new Date().getTime();
        var filename = md5(time + file.name.split(".")[0]) + "." + imgType;
        console.log(filename);
        ossData.append("work_path", "admin/notify");
        ossData.append("bucket", "family-tcm");
        ossData.append("end_point", "oss-cn-hangzhou.aliyuncs.com");
        ossData.append("rewrite", "1");
        ossData.append("file_name", filename);
        ossData.append("file", file);
        let apiURL = "";
        if (process.env.NODE_ENV === "development") {
          // alert("开发环境");
          apiURL = "http://proxy.jiankanghao.net:50215/api/v1/";
        } else {
          // alert("生产环境");
          apiURL = uploadapi;
        }
        $.ajax({
          url: apiURL + "upload",
          type: "POST",
          data: ossData,
          contentType: false,
          processData: false,
          success: function (data) {
            console.log(data);
            that.imageUrl = "admin/notify/" + filename;
            that.cover = URL.createObjectURL(file);
            that.form.img_path = that.imageUrl;
          },
          error: function () {
            console.log("上传失败！");
          },
        });
      } else if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      } else if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    formatDate(time) {
      return new Date(time * 1000).Format("YYYY-MM-DD hh:mm:ss");
    },
    formatDates(time) {
      return Date.parse(new Date(time).Format("YYYY-MM-DD hh:mm:ss"));
    },
    sendStatus(sendStatus) {
      return sendStatus == "1" ? "已发送" : "待发送";
    },
    getPlatformNype(id) {
      var item = _.filter(this.device_types, function (selector) {
        return selector.id == id;
      })[0];
      if (item) {
        return item["name"];
      } else {
        return "";
      }
    },
    getusertype(id) {
      var item = _.filter(this.user_types, function (selector) {
        return selector.id == id;
      })[0];
      if (item) {
        return item["name"];
      } else {
        return "";
      }
    },
    getData() {
      let that = this;
      that.loading = true;
      let params = that.ruleForm;
      params["page"] = that.currentPage;
      params["page_size"] = that.page_size;
      http.get("/admin/notify", { params: params }).then(function (res) {
        that.page_index = (that.currentPage - 1) * that.page_size;
        that.tableData = res.data.data.messages;
        that.currentPage = res.data.data.page;
        that.totalPages = res.data.data.total_pages;
        that.pageTotal = that.totalPages * that.page_size;
        that.loading = false;
      });
    },
    getTransferData() {
      let that = this;
      const cities = ["上海", "北京", "广州", "深圳", "南京", "西安", "成都"];
      const pinyin = [
        "shanghai",
        "beijing",
        "guangzhou",
        "shenzhen",
        "nanjing",
        "xian",
        "chengdu",
      ];
      cities.forEach((city, index) => {
        that.transferData.push({
          label: city,
          key: index,
          pinyin: pinyin[index],
        });
      });
    },
    //选择
    selectHandle() {
      this.currentPage = 1;
      this.getData();
    },
    // 触发搜索按钮
    handleSearch() {
      this.currentPage = 1;
      this.getData();
    },
    // 删除操作
    handleDelete(index, row) {
      let that = this;
      // 二次确认删除
      that
        .$confirm("确定要删除吗？", "提示", {
          type: "warning",
        })
        .then(() => {
          http.delete("/admin/notify/" + row.id).then((res) => {
            // console.log(res);
            if (res.data.status_code == 200) {
              that.$message.success("删除成功");
              that.getData();
            }
          });
        })
        .catch(() => {});
    },
    onInput() {
      this.$forceUpdate();
    },
    // 编辑操作
    handleEdit(row) {
      this.form = {};
      for (let key in row) {
        this.form[key] = row[key];
      }
      this.is_add = false;
      this.editVisible = true;
    },
    //新增操作
    addEdit() {
      this.form = {};
      this.cover = "";
      this.window_type = 1;
      this.window_platform_type = this.platform_type;
      this.search_users = [];
      this.window_keyword = "";
      this.doctors = [];
      this.window_doctor_keyword = "";
      this.is_add = true;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let extras = {
            type: that.window_type,
            url: that.form.url,
            img_path: that.form.img_path,
          };
          let time =
            that.window_send_type == "2"
              ? that.formatDates(that.form.send_time)
              : "";
          let params = {
            content: that.form.content,
            title: that.form.title,
            user_id: that.form.user_id ? that.form.user_id : "",
            platform_type: that.window_platform_type,
            send_time:
              that.window_send_type == "2"
                ? Number(time.toString().substring(0, 10))
                : 0,
            send_status: that.window_send_type,
            device_type: that.window_device_type,
            user_type: that.user_type,
            remark: that.form.remark,
            extra: JSON.stringify(extras),
          };
          http.post("/admin/notify", params).then(function (res) {
            if (res.data.status_code == 200) {
              that.currentPage = 1;
              that.getData();
            } else {
              that.$message.error(res.data.msg);
            }
          });
          that.editVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 分页导航
    handlePageChange(val) {
      this.currentPage = val;
      this.getData();
    },
    selecsendTypeHandle(e) {
      console.log(e);
      this.window_send_type = e;
      this.$forceUpdate();
    },
    selecdeviceTypeHandle(e) {
      console.log(e);
      this.window_device_type = e;
      this.$forceUpdate();
    },
    selecuserTypeHandle(e) {
      console.log(e);
      this.user_type = e;
      this.$forceUpdate();
    },
    selecDevicetHandle() {
      if (this.device_type == "user_id" || this.device_type == "doctor_id") {
        this.form.devices = "";
        this.form.devices_value = [];
      } else {
        this.form.devices = this.device_type;
      }
      this.$forceUpdate();
    },
    handleWindowSearchDoctors() {
      let that = this;
      let params = {
        page: 1,
        page_size: 10,
      };
      params["keywords"] = that.window_keyword;
      http3
        .get("/admin/doctors/can_push", { params: params })
        .then(function (res) {
          that.doctors = res.data.data.items;
        });
    },
    handleNodeClick(data) {
      this.$refs.doctor_tree.setCheckedKeys([]);
      this.$refs.doctor_tree.setCheckedNodes([
        { id: data["id"], label: data["phone"] },
      ]);
      this.form.select_doctor_id = data.id;
    },
    handleWindowSearch() {
      let that = this;
      let params = {
        page: 1,
        page_size: 20,
      };
      if (that.device_type == "user_id") {
        params["keyword"] = that.window_keyword;
        http2.get("/admin/user_all", { params: params }).then(function (res) {
          let users_data = res.data.data;
          let select_item = _.filter(that.search_users, function (selector) {
            return that.select_users.indexOf(selector.id) > -1;
          });
          users_data = _.filter(users_data, function (selector) {
            return that.select_users.indexOf(selector.id) < 0;
          });
          that.search_users = users_data.concat(select_item);
        });
      } else {
        params["keywords"] = that.window_keyword;
        http3
          .get("/admin/doctors/can_push", { params: params })
          .then(function (res) {
            let users_data = res.data.data.items;
            let select_item = _.filter(that.search_users, function (selector) {
              return that.select_users.indexOf(selector.id) > -1;
            });
            users_data = _.filter(users_data, function (selector) {
              return that.select_users.indexOf(selector.id) < 0;
            });
            that.search_users = users_data.concat(select_item);
          });
      }
    },
    handleTransferChange(value, direction, movedKeys) {
      console.log(value);
      console.log(direction, movedKeys);
      console.log(movedKeys);
      let that = this;
      that.select_users = value;
      if (that.select_users.length > 0) {
        that.form.devices_value = that.select_users.join(",");
      } else {
        that.form.devices_value = "";
      }
    },
  },
};
</script>

<style scoped>
.doctor-tree {
  border: 1px solid #dcdfe6;
}
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.self-dialog-footer {
  display: block;
  text-align: center;
}
</style>
